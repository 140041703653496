import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom"
import { usePitches } from 'hooks/UsePitches'
import { QaTopic } from 'Pages/Founder/Interview/QaTopic'
import { SendPitchToAIScript } from 'Controllers/AdminController'
import { QT_Null } from 'Model/Types'
import { RoutingController } from 'Controllers/RoutingController'
import { useAlert } from 'hooks/UseAlert'
import { useSpinner } from 'UI Elements/XJSpinner'
import { LogError } from 'Controllers/Logging'
import { useContext } from 'react'
import BigAlertContext from 'UI Elements/XJBigAlerts'
import { PitchModel } from 'Model/PitchModel'
import { TopicModel } from 'Model/TopicModel'
import { NotFoundBanner } from 'UI Elements/NotFoundBanner'

export function SegmentEditTopicPage() {
    const { pid } = useParams<any>()        // Pitch ID 
    const { tid } = useParams<any>()        // topic ID

    const pitch = usePitches().getPitch(Number(pid))

    let topic

    if (pitch) {
        topic = pitch.getTopic(Number(tid))
    }

    return pitch && topic ? 
            <SegmentEditTopic pitch={pitch} topic={topic} />
            :
            <NotFoundBanner
                text={!pitch ? 'This pitch cannot be accessed' : "This topic cannot be accessed"}
                urlText='Go home'
                url={RoutingController.Home()}
            />
}

interface SegmentEditTopicProps {
    pitch: PitchModel
    topic: TopicModel
}

function SegmentEditTopic(props: SegmentEditTopicProps) {

    const errorAlert = useAlert().error
    const spinner = useSpinner()
    const pitchHook = usePitches()
    const history = useHistory()

    const pitch = props.pitch
    const currentTopic = props.topic
    const segment = currentTopic.segment

    const NextPrevURL = (step: QT_Null): string => {

        const URL = RoutingController.QA_Edit(step)

        return URL ? URL : RoutingController.SegmentEditor(segment)

    }

    const startTopic = () => {

        const nextQuestion = segment.goNext(currentTopic)
        history.push(NextPrevURL(nextQuestion))

    }

    const goBack = () => {

        const prevQuestion = segment.goBack(currentTopic)
        history.push(NextPrevURL(prevQuestion))

    }

    const SkipTopic = () => {

        try {

            const NextTopic = segment.skipTopic(currentTopic)
            history.push(NextPrevURL(NextTopic))

        }
        catch (err) {
            LogError(err)
            errorAlert(err)
        }
    }

    const ErrorAlert = useContext (BigAlertContext).error

    const FinishLater = async () => {

        spinner(true)

        SendPitchToAIScript(pitch)
            
            .then(result => {
                if (result && pitch && pitch.id) {
                    pitchHook.reloadPitch(pitch.id)
                }
            })

            .catch(err => {

                LogError(err)
                ErrorAlert ("Error processing script. Your changes are saved - we're working on fixign the issue.", 'Server error')

            })

            .finally(() =>
            {
                spinner(false)
                history.push(RoutingController.SegmentEditor(currentTopic.segment))
            })
    }

    return (
        <QaTopic
            currentTopic={currentTopic}
            onSkip={SkipTopic}
            onPrevious={goBack}
            onFinishLater={FinishLater}
            onStart={startTopic}
            FinishLaterLabel={'Save'}
        />
    )
}
