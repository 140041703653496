import { Box, Grid, SxProps, Typography } from "@mui/material"

import { VideoModel } from "Model/VideoModel"

import { Header, HeaderRightButtons, PageTitle, theme } from "XJumpTheme"
import { useContext, useEffect, useState } from "react"
import { VideoPlayer, mapVideoModel } from "Pages/Video/Player/VideoPlayer"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { DragNDropMedia, UploadType, DragNDropContentVideo } from "../VideoEditor/DragNDropMedia"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { usePitches } from "hooks/UsePitches"
import { PitchModel } from "Model/PitchModel"
import { RoutingController } from "Controllers/RoutingController"
import { ExplanationBox } from "UI Elements/XJExplanationBox"
import { showSegmentBadge } from "./SegmentEditor"
import { FileUploadEntry, MediaUploadStatus, useXJMediaUploadContext } from "Components/XJMediaUpload"
import { useAlert } from "hooks/UseAlert"
import { useSpinner } from "UI Elements/XJSpinner"
import BigAlertContext from "UI Elements/XJBigAlerts"
import { LogError } from "Controllers/Logging"
import { SegmentModel } from "Model/SegmentModel"
import { NotFoundBanner } from "UI Elements/NotFoundBanner"

export function UploadVideoPage() {

    const { pid } = useParams<any>()
    const { sid } = useParams<any>()

    const pitchId = Number(pid)
    const segmentId = Number(sid)

    const pitch = usePitches().getPitch(pitchId) as PitchModel  

    let segment
    if (pitch) {
        segment = pitch.getSegmentById(segmentId)
    }

    return pitch && segment ? 
        <UploadVideo 
            pitch={pitch}
            segment={segment}
        />
        : 
        <NotFoundBanner
            text={!pitch ? 'This pitch cannot be accessed' : "This segment cannot be accessed"}
            urlText='Go home'
            url={RoutingController.Home()}
        />
}

interface UploadVideoProps {
    pitch: PitchModel
    segment: SegmentModel
}

function UploadVideo(props: UploadVideoProps) {

    enum UploadStep {
        selectFile = 0,
        uploading = 1,
        hasVideo = 2
    }

    const history = useHistory<any>()

    const segment = props.segment

    const [step, setStep] = useState<UploadStep>(UploadStep.selectFile)
    const [video, setVideo] = useState<VideoModel>()

    const [uploadingEntryStatus, setUploadingEntryStatus] = useState<MediaUploadStatus>()
    const isUploadedInitiated = uploadingEntryStatus === MediaUploadStatus.started ||
                                uploadingEntryStatus === MediaUploadStatus.Progressing

    const uploaderRef = useXJMediaUploadContext()

    const BigAlert = useContext(BigAlertContext)
    const spinner = useSpinner()
    const errorAlert = useAlert().APIError

    
    // MARK: - Actions 

    const onCancel = () => {

        // reset? 
        if (uploaderRef.current) {
            uploaderRef.current.cancelUpload()
        }

        history.push(RoutingController.SegmentEditor(segment))
        // setStep(UploadStep.selectFile)
    }

    const onDone = async () => {

        // get back to segment editor 
        if (video) {

            setIsSaving (true)
            spinner(true)

            segment.setVideo(video).then(res => {

               
                history.push(RoutingController.SegmentEditor(segment))
                BigAlert.success ('Video uploaded successfully', 'Video upload')

            })
                .catch(err => {

                    spinner(false)
                    errorAlert()
                    LogError(err)
                })

        }

    }


    const [isUploading, setIsUploading] = useState(false)

    const [isSaving, setIsSaving] = useState (false)

    const onStartedUpload = (entry: FileUploadEntry) => {

        setUploadingEntryStatus(entry.status)
        setIsUploading(true)

    }
    
    const onProgressingUpload = (isProgressing: boolean) => {

        if (isProgressing) setUploadingEntryStatus(MediaUploadStatus.Progressing)
        setIsUploading(isProgressing)

    }

    const onCompleteUpload = (entry: FileUploadEntry) => {

        setUploadingEntryStatus(entry.status)
        setIsUploading(false)

    }

    const onFailedUpload = (entry: FileUploadEntry) => {

        setUploadingEntryStatus(entry.status)
        setIsUploading(false)

    }

    const onAbortedUpload = () => {

        setUploadingEntryStatus(MediaUploadStatus.aborted)
        setIsUploading(false)

    }

    useEffect(() => {

        uploaderRef.current?.setonUploadStartedFunction(onStartedUpload)
        uploaderRef.current?.setonUploadInProgressFunction(onProgressingUpload)
        uploaderRef.current?.setonUploadCompleteFunction(onCompleteUpload)
        uploaderRef.current?.setonUploadFailedFunction(onFailedUpload)
        uploaderRef.current?.setonUploadAbortedFunction(onAbortedUpload)

    }, [ uploaderRef.current])

    useEffect(() => {

        switch (uploadingEntryStatus) {
            case (MediaUploadStatus.started):

                setStep(UploadStep.uploading)
                setIsUploading (true)
                break
            
            case (MediaUploadStatus.Progressing):


                setStep(UploadStep.uploading)
                setIsUploading (true)
                break

            // case (MediaUploadStatus.completed):
            case (MediaUploadStatus.uploaded):
                setStep(UploadStep.hasVideo)
                break

            default:
                setStep(UploadStep.selectFile)

        }

    }, [uploadingEntryStatus])


    const onSelected = (mediaURL: string, aFilename: string, anExtension: string, aSize: number) => {

       setIsUploading (true)

        fetch(mediaURL)

            .then((res: Response) => {
                return res.blob()
            })

            .then((blob) => {

                const newVideo = new VideoModel()
                newVideo.uploaderRef = uploaderRef
                setVideo(newVideo)

                newVideo.setMedia(blob, false, new Date(), undefined, aFilename)
                    .then(entry => {

                        if (entry) {
                            setUploadingEntryStatus(entry.status)
                        }

                    })
                    .catch(reason => {
                        setStep(UploadStep.selectFile)
                        LogError(reason)

                    })
            })
            .catch(res => {
                setStep(UploadStep.selectFile)
                setIsUploading(false)
                LogError(res)
            })
    }

   
    // MARK: - Render 

    const Upload = () => {

        const styles: { [id: string]: SxProps } = {
            root: {
                overflow: 'hidden'
            },
            explainerBox: {
                mt: 8,
                mb: 16,

            } as SxProps,
            copy: {
                ...theme.typography.primary.regular,
                color: theme.palette.darkPrimary.default,
                textAlign: 'left'
            } as SxProps,
            dragndrop: {
                width: "100%",
                aspectRatio: '16 / 9'
            }
        }

        return (
            <Box sx={styles.root}>
                <Box sx={styles.explainerBox}>
                    <ExplanationBox>
                        Upload your own video for this segment. It's recommended to keep it under 30 seconds long.  For product a demo, pick the one feature that stands out and make it special.
                    </ExplanationBox>
                </Box>

                {(step === UploadStep.selectFile || step === UploadStep.uploading) &&
                    <Box sx={styles.dragndrop}>
                        <DragNDropMedia
                            type={UploadType.video}
                            content={DragNDropContentVideo}
                            onMediaSelected={onSelected}
                            isUploading={isUploading}
                            disabled={isUploadedInitiated}
                        />
                    </Box>}

                {(step === UploadStep.hasVideo) &&
                    <>
                        {video &&
                            <VideoPlayer
                                id={"uploadingVideoPlayerID"}
                                video={mapVideoModel(video)}

                                showDefaultTimeline
                                showDefaultControls
                            />}

                    </>}
            </Box>
        )
    }

    return <>
       
        <Prompt
            
            when={(step === UploadStep.uploading || step === UploadStep.hasVideo) && !isSaving}
            message={"Uploaded file not saved yet. If you continue, the upload will not complete."}/ >

        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>

                    <XJButton onClick={onCancel} variant={'tertiary'}>Cancel</XJButton>

                    <XJButton
                        onClick={onDone}
                        variant={'secondary'}
                        disabled={(uploadingEntryStatus !== MediaUploadStatus.uploaded)}
                    >
                        Save
                    </XJButton>

                </Box >
            </Grid >
            <Grid item xs={12} sx={PageTitle} gap={8}>
                <Typography variant='h1' color='secondary'>
                    {segment.title}
                </Typography>
                {showSegmentBadge(segment)}
            </Grid>

            <Grid item xs={8}>
                <Upload />
            </Grid>

        </Grid>
    </>

}
