import { Box, Grid, Step, StepIcon, StepLabel, Stepper, SxProps, Typography } from "@mui/material"
import MicCameraPermissionAlert from "Components/MicCameraPermissionAlert"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { Header, HeaderRightButtons, PageTitle, theme } from "XJumpTheme"
import VideoSetupFrame from "./VideoSetupFrame"
import VideoSetupProc from "./VideoSetupProc"
import VideoSetupVolume from "./VideoSetupVolume"
import { useAuth } from "hooks/UseAuth"
import { RoutingController } from 'Controllers/RoutingController'
import { usePitches } from "hooks/UsePitches"
import { SegmentModel } from "Model/SegmentModel"
import { PitchModel } from "Model/PitchModel"
import { NotFoundBanner } from "UI Elements/NotFoundBanner"

export const classes = {

    explaination:
        {
            width: '500px',
            marginX: 'auto',
            color: theme.palette.darkPrimary.default,
            ...theme.typography.primary.regular,
            marginTop: '16px'

        } as SxProps,

    mainBox:
    {
        width: '500px',
        // minHeight: '300px',
        marginX: 'auto',
        backgroundColor: 'white',
        marginTop: '48px',
        // marginBottom: '32px'
    },

    buttonBox:
    {
        marginTop: 16,
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto'

    },
    header:

        {
            textAlign: 'center',
            marginTop: '48px'

        } as SxProps,

   
    stepperBox:
        {
            width: '280px',
            marginLeft: 'auto',
            marginRight: 'auto',


            '& .MuiStepConnector-line':
            {
                borderTopStyle: 'dashed',
                color: theme.palette.darkPrimary.default,
                width: '75%',
                marginLeft: 'auto',
                marginRight: 'auto'
            }

            ,
            '& .MuiStepIcon-root':
            {
                '& .MuiStepIcon-text':
                {
                    fill: theme.palette.darkPrimary.default,
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    textTransform: 'uppercase'

                },
                color: theme.palette.white.default
            },

            '& .MuiStepIcon-root.Mui-active':
            {
                color: theme.palette.secondary.default,
                '& .MuiStepIcon-text':
                {
                    fill: theme.palette.white.default,

                },

            },

            ' .MuiStepIcon-root.Mui-completed':
            {
                color: theme.palette.darkPrimary.default,

                '& .MuiStepIcon-text':
                {
                    fill: theme.palette.white.default,

                }
            },
            ' .Mui-completed':
            {
                '& .MuiStepIcon-root':
                {
                    color: theme.palette.darkPrimary.default
                }
            }

        } as SxProps
}

export function VideoSetupPage() {

    const { pid } = useParams<any>()
    const { sid } = useParams<any>()

    const pitchId = Number(pid)
    const segmentId = Number(sid)

    const pitch = usePitches().getPitch(pitchId) as PitchModel

    let segment
    if (pitch) {
        segment = pitch.getSegmentById(segmentId)
    }

    return pitch && segment ? 
        <VideoSetup 
            pitch={pitch}
            segment={segment}
        />
        : 
        <NotFoundBanner
            text={!pitch ? 'This pitch cannot be accessed' : "This segment cannot be accessed"}
            urlText='Go home'
            url={RoutingController.Home()}
        />
}

interface VideoSetupProps {
    pitch: PitchModel
    segment: SegmentModel
}

function VideoSetup(props: VideoSetupProps) {

    const history = useHistory()
    const session = useAuth().user.Session
    
    const segment = props.segment

    const [completedSteps, setCompleteSteps] = useState(new Set<number>())
    const [ActiveStep, setActiveStep] = useState(0)

    const handleNextStep = () => {

        if (ActiveStep === 2) {

            session.completedVideoSetup = true
            history.push(RoutingController.SegmentVideoRecord(segment))

        } else {
            setActiveStep((prev) => prev + 1)
        }

    }

    const handlePrevStep = () => {

        if (ActiveStep == 0) {

            history.push(RoutingController.SegmentVideoRecordSetupIntro(segment))

        } else {
            setActiveStep((prev) => prev - 1)
        }

    }
    
    return <>

        <MicCameraPermissionAlert />
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton variant={'tertiary'} to={RoutingController.SegmentEditor(segment)}>
                        Cancel
                    </XJButton>

                    <XJButton to={RoutingController.SegmentVideoRecord(segment) } variant={'secondary'}>
                        Skip
                    </XJButton>
                </Box>
            </Grid>

            <Grid xs={12} item sx={{ ...PageTitle, mb: 0 }}  >
                <Typography variant="h1">Record</Typography>
            </Grid>

            <Grid xs={12} item>
                <Box sx={classes.stepperBox}>
                    <Stepper activeStep={ActiveStep} alternativeLabel  >

                        <Step key={'sound'} completed={ActiveStep > 0}>
                            <StepLabel 
                                StepIconComponent={(props) => (
                                    <StepIcon
                                        {...props}
                                        icon={props.icon}
                                        active={props.active || props.completed}
                                        completed={false}
                                    />
                                )}

                            ></StepLabel>
                        </Step>


                        <Step key={'proc'} completed={ActiveStep > 1}>
                            <StepLabel
                                StepIconComponent={(props) => (
                                    <StepIcon
                                        {...props}
                                        icon={props.icon}
                                        active={props.active || props.completed}
                                        completed={false}
                                    />
                                )}

                            ></StepLabel>
                        </Step>

                        <Step key={'light'} >
                            <StepLabel
                                StepIconComponent={(props) => (
                                    <StepIcon
                                        {...props}
                                        icon={props.icon}
                                        active={props.active || props.completed}
                                        completed={false}
                                    />
                                )}
                            ></StepLabel>
                        </Step>

                    </Stepper>
                </Box>
            </Grid>

            <Grid xs={12} item>

                {
                    (ActiveStep === 0) && <VideoSetupVolume onComplete={() => {

                        setCompleteSteps((value) => new Set(value.add(0)))

                    }}></VideoSetupVolume>

                }

                {
                    (ActiveStep === 1) && <VideoSetupProc onComplete={() => {

                        setCompleteSteps((value) => new Set(value.add(1)))

                    }}></VideoSetupProc>

                }

                {
                    (ActiveStep === 2) && <VideoSetupFrame onComplete={() => {

                        setCompleteSteps((value) => new Set(value.add(2)))

                    }}></VideoSetupFrame>

                }

            </Grid>

            <Grid xs={12} item>

                <Box sx={classes.buttonBox} display={'flex'} gap={8} justifyContent={'space-between'}>

                    <XJButton variant={'tertiary'} onClick={handlePrevStep}>
                        Back
                    </XJButton>

                    <XJButton variant={'secondary'} onClick={handleNextStep}>
                        Next
                    </XJButton>

                </Box>
            </Grid>

        </Grid>
    </>
}