import { useEffect, useRef, useState } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { QuestionModel } from '../../../Model/QuestionModel'
import { QuestionType } from 'Common/Enums'
import { QaLayout } from './QaLayout'
import {  XJButton } from 'UI Elements/Buttons/XJButton'
import { ArrowLeftIcon, ArrowRightIcon } from 'UI Elements/XJIcons'
import { useKeyPress } from 'hooks/UseKeyPress'
import { SendPitchToAIScript } from 'Controllers/AdminController'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'
import { useSpinner } from 'UI Elements/XJSpinner'
import { usePitches } from 'hooks/UsePitches'
import { LogError } from 'Controllers/Logging'
import { QuestionSelection } from 'Common/QuestionContentDAO'
import { useAlert } from 'hooks/UseAlert'

export interface IQaQuestionProps {

    currentQuestion: QuestionModel
    onNextQuestion: () => void
    onPrevQuestion: () => void
    onFinishLater: () => void
    FinishLaterLabel?: string,
    clarify?: boolean

}

export function QaQuestion(props: IQaQuestionProps) {

    const spinner = useSpinner()
    const pitchHook = usePitches()
    const alert = useAlert()

    const currentQuestion = props.currentQuestion
    const [currentAnswer, setCurrentAnswer] = useState('')

    const [multiAnswerClicked, setMultiAnswerClicked] = useState(false)

    const explanation = currentQuestion.explanation
    const qType = currentQuestion.type as QuestionType
    const qMultipleAnswers = currentQuestion.qMultipleAnswers
    const title = currentQuestion.title


    // Handle events 

    const setQuestionAnswer = () => {

        if (!currentQuestion.answer || 
            (currentQuestion.answer && (currentQuestion.answer.value !== currentAnswer))) 
        {
            currentQuestion.setAnswer(currentAnswer)
            currentQuestion.save(true)
                .catch((err) => {
                    alert.APIError()
                })
        }

    }

    const handleChange = (event: any) => {

        setCurrentAnswer(event.target.value)
        setMultiAnswerClicked(true)

        // event.target.blur()
    }

    useEffect(() => {

        if (currentQuestion.type === QuestionType.select) {
            if (currentQuestion.answer && currentQuestion.answer.value !== '') {
                setMultiAnswerClicked(true)
                setCurrentAnswer(currentQuestion.answer.value)
            }
            else {
                setMultiAnswerClicked(false)
                setCurrentAnswer('')
            }
        }
        else {

            if (currentQuestion.answer && currentQuestion.answer.value)
                setCurrentAnswer(currentQuestion.answer.value)
            else
                setCurrentAnswer('')

        }
    }, [currentQuestion])

    const renderInputControl = (qType: number, qMultipleAnswers: []) => {

        const styles = {
            radioGroup: {
                width: '100%'
            } as SxProps
        }

        const radioRef = useRef<HTMLInputElement>()

        useEffect(() => {
            if (radioRef.current) {
                radioRef.current.focus()
            }
        }, [radioRef.current, currentQuestion])

        const setRef = (ref: HTMLInputElement, index: number) => {
            if (ref) {
                let startIndex = 0 
                if (currentQuestion.qMultipleAnswers) {
                    startIndex = currentQuestion.qMultipleAnswers.length - 1
                }

                if (currentQuestion.hasAnswer()) {
                    startIndex = Number(currentQuestion.answer.value)
                }

                if (index === startIndex) {
                    radioRef.current = ref
                }
            }
        }

        switch (qType) {

            case QuestionType.open: // text input 

                {
                    return <TextField
                        onChange={handleChange}
                        color={'secondary'}
                        value={currentAnswer}
                        autoFocus
                        fullWidth
                        inputRef={input => input && input.focus()}
                        key='openquestion' 
                    />
                }

            case QuestionType.select: // selection 
                {
                    return <FormControl component="fieldset" sx={styles.radioGroup}>
                        <RadioGroup aria-label="answers"
                            name="Q"
                            value={currentAnswer}
                            onChange={handleChange}>

                            <Box display='flex' flexWrap='wrap' flexDirection="column">
                                {qMultipleAnswers.map((Q: QuestionSelection, index: number) => {
                                    return <FormControlLabel key={'radio' + index} 
                                        value={Q.index}
                                        control={<Radio color='secondary' inputRef={ref => setRef(ref, index)}/>}
                                        label={Q.title}            
                                    />
                                })}
                            </Box>
                        </RadioGroup>
                    </FormControl>
                }

            case QuestionType.openmultiline: // multiline text area
                {
                    return <>

                        <TextField
                            onChange={handleChange}
                            color={'secondary'}
                            value={currentAnswer}
                            multiline={true}
                            autoFocus
                            fullWidth
                            rows={5}
                            inputRef={input => input && input.focus()}
                            key='multilineopenquestion' 
                        />
                    </>

                }
            default:
                {
                    LogError("ERROR in renderInputControl")
                }
        }
    }

    const onClickNext = () => {

        if (props.clarify) return

        if (currentQuestion.type === QuestionType.select && !multiAnswerClicked) return
        setQuestionAnswer()
        currentQuestion.topic.updateStatus()
        props.onNextQuestion()

    }

    const onClickBack = () => {

        if (props.clarify) return
        setQuestionAnswer()
        props.onPrevQuestion()

    }

    const onClarificationSave = async () => {

        setQuestionAnswer()
        spinner(true)
        const pitch = currentQuestion.segment.pitch
        const result = await SendPitchToAIScript(pitch)
        if (result && pitch && pitch.id) {
            pitchHook.reloadPitch(pitch.id)
        }
        spinner(false)
        props.onFinishLater()

    }

    useKeyPress({ char: "Enter" }, () => { if (currentQuestion.type !== QuestionType.openmultiline) onClickNext() })
    //useKeyPress({ char: "Backspace" }, () => { if (currentQuestion.type !== QuestionType.openmultiline) onClickBack() })


    const styles = {
        root: {
            display: "flex",
            flexDirection: 'column',
            gap: 16,
            justifyContent: "space-between"
        } as SxProps,
        title: {
            display: "flex",
            alignItems: 'center',
        } as SxProps,
        question: {
            maxWidth: '592px'
        } as SxProps,
        description: {
            maxWidth: '592px'
        } as SxProps,
        controls: {
            display: "flex",
            flexWrap: "wrap"
        } as SxProps,
        buttons: {
            display: 'flex',
            gap: 16
        } as SxProps
    } 


    const RenderQuestion = <>

        <Box sx={styles.root} >
            <Box sx={styles.title}>
                <Typography variant='h2' sx={styles.question}>
                    {title}
                </Typography>
            </Box>

            <ExplanationBox sx={styles.description}>
                {explanation}
            </ExplanationBox>

            <Box sx={styles.controls}>
                {renderInputControl(qType, qMultipleAnswers as [])}
            </Box>

            <Box sx={styles.buttons}>

                {!props.clarify ? 
                <>
                    <XJButton variant={'tertiary'} iconLeft onClick={onClickBack}>
                        <ArrowLeftIcon /> Back
                    </XJButton>

                    <XJButton
                        variant={'secondary'}
                        disabled={currentQuestion.type === QuestionType.select && multiAnswerClicked === false}
                        iconRight
                        onClick={onClickNext}
                    >
                        Next 
                        <ArrowRightIcon />
                    </XJButton> 
                </> 
                :
                    <XJButton
                        variant={'secondary'}
                        onClick={onClarificationSave}
                    >
                        Save
                    </XJButton>
                }

            </Box>
        </Box>
    </>

    return <>
        <QaLayout
            TopicQuestion={RenderQuestion}
            Tutotrial={currentQuestion.tutorials}
            TutorialVideo={currentQuestion.tutorialVideoLink}
            FinishLaterLabel={props.FinishLaterLabel}
            segment={currentQuestion.segment}
            QAProgress={currentQuestion.pitch.getQAPercentCompleted()}
            onFinishLater={() => {
                setQuestionAnswer()
                props.onFinishLater()
            }}
        />
    </>

}
