import axios from "axios"
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "Common/HTTPCodes"
import { LogError } from "Controllers/Logging"
import { RoutingController } from "Controllers/RoutingController"
import { RouterChildContext } from "react-router-dom"


const API = axios.create({
    baseURL: process.env.REACT_APP_APIUrl,
    headers: {
        "Content-Type": "application/json"
    }
})


let updating = false

export const setupAxiosInterceptors = (history: RouterChildContext['router']['history']) => {

    const controller = new AbortController();

    API.interceptors.request.use(

        (config) => {

            if (globalThis.JWTToken) {
                config!.headers!['authorization'] = `Bearer ${JWTToken}`
            }

            if (config.method === 'put' || config.method === 'delete' || config.method === 'post') {
                if (updating) {
                    controller.abort()
                }

                updating = true
            }

            config.withCredentials= true
            return config

        }, (error) => {
            LogError (error)
            return Promise.reject(error)
        })


    API.interceptors.response.use((response) => {

        updating = false

        return response

    }, function (error) {

        updating = false

        if (error.response) {
            

            if (error.response.status === HTTP_UNAUTHORIZED) {
                history.push(RoutingController.Login(history.location.pathname + history.location.search))
                return Promise.reject(error)
            }
            else
            {
                LogError (error)
                return Promise.reject(error)

                if (error.response.status === HTTP_FORBIDDEN) {
                    // incorrect password 
                    return Promise.reject(error)
                }
            }

        }
        else {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            LogError(error)
            return Promise.reject(error)
        }



    })
}

export default API