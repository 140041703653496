import API from "api-axios"
import { LinkDAO } from "Common/LinkDAO"
import { PitchModel } from "./PitchModel"
import { RoutingController } from "Controllers/RoutingController"
import { LogError } from "Controllers/Logging"
import { HTTP_SUCCESS } from "Common/HTTPCodes"


export default class LinkModel {

    constructor(_pitch: PitchModel) {
        this.pitch = _pitch
    }

    private baseURL: string | undefined = globalThis.BaseURL
    private pitch: PitchModel

    title: string

    viewsCount = 0
    _active: boolean
    id: number | undefined
    shortCode: string
    createAt?: Date
    avgPercentPlayed?: number
    deleted = false 

    private _lastView: Date | null = null

    set lastView(value: Date | null) {
        this._lastView = value

    }

    GetAvgPercentPlayed(): string {
        return this.avgPercentPlayed ? String(this.avgPercentPlayed) : "0"
    }

    GetlastView(): string {
        if (this._lastView) {
            return this._lastView.toDateString()
        }else {
            return "Never"
        }
    }


    get active(): boolean {
        return this._active
    }

    get UniqueLink(): string {

        const host = window.location.protocol + '//' + window.location.host

        return `${host}` + RoutingController.Watch(this.shortCode)
    }

    async SetActive(value: boolean) {

        const data = { 'active': value }
        this._active = value
        await API.put(`/link/${this.id}/active`, data)
            .catch(err => {
                this._active = !value
                throw(err)
            })
    }

    load = (data: LinkDAO) => {

        this.id = data.id
        this.title = data.title
        this.shortCode = data.shortCode
        this._active = data.active
        this.createAt = data.createAt ? new Date(data.createAt) : undefined
        if (data.lastView) this.lastView = data.lastView
        if (data.viewsCount) this.viewsCount = data.viewsCount
        this.avgPercentPlayed = data.avgPercentPlayed

        this.pitch._links.push(this)
    }


    delete = async () => {

        try {
            const result = await (await API.delete(`/link/${this.id}`)).status
            if (result === HTTP_SUCCESS) {
                this.deleted = true 
            }
        }
        catch (err) {
            LogError(err)
            throw err
        }

    }

    new = async (title: string) => {

        try {
            const linkData = await (await API.post(`/pitch/${this.pitch.id}/link`, { 'pitchId': this.pitch.id, 'title': title })).data as LinkDAO

            this.id = linkData.id
            this.shortCode = linkData.shortCode
            this.createAt = linkData.createAt
            this._active = linkData.active
            this.title = title
            this.pitch._links.push(this)
        }
        catch (err) {
            LogError(err)
            throw err 
        }

    }


}