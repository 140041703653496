import * as React from "react";
import { UserModel } from 'Model/UserModel';
import SessionModel from "Model/SessionModel";
import { UserDAO } from "Common/UserDAO";
import API from "api-axios"
import { AxiosError, AxiosResponse } from "axios";
import { OAuthProvider, PitchuraRole } from "Common/Enums";
import { VentureDAO } from "Common/VentureDAO";
import { useAlert } from "./UseAlert";
import { useLocation } from "react-router-dom";
import { LogError } from "Controllers/Logging";
import { usePitchuraLocalStorage } from "./UsePitchuraLocalStorage";

export interface IAuthData {
  Id: number
  JWTtoken: string
  User: UserModel | null
  Session: SessionModel

}


interface IProviderAuth {

  user: IAuthData
  isAdmin(): boolean
  EmailLogin: (email: string, password: string, OnSuccess: () => void, OnError: (msg: string) => void) => void
  Logout: (cb: () => void) => void
  EmailSignup: (email: string, password: string, OnSuccess: () => void, OnError: (msg: string) => void) => void
  EmailRequestPasswordChange: (email: string, onSuccess: () => void, onError: (msg: string) => void) => void
  EmailChangePassword: (email: string, challenge: string, newPassword: string, onSuccess: () => void, onError: (msg: string) => void) => void
  SendEmailVerification: (onSuccess: () => void, onError: (msg: string) => void) => void
  VerifyEmail: (email: string, challange: string, onSuccess: () => void, onError: (msg: string) => void) => void
  UpdateUser: (onSuccess: () => void, onError: (msg: string) => void, userId: number, email?: string, phone?: string, firstName?: string, lastName?: string, title?: string, linkedInURL?: string, companyCity?: string, companyLogo?: string, companyName?: string, companyCountry?: string, companyState?: string) => void

  OauthCallback: (provider: OAuthProvider, CallbackQS: string, onSuccess: () => void, onError: () => void) => void
  EmailVerifiedStatus: boolean
}

interface IProps {
  children: React.ReactNode;
  // any other props that come into the component
}

const authContext = React.createContext<IProviderAuth>({} as IProviderAuth);

export function ProvideAuth({ children }: IProps) {

  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(authContext);
}


function useProvideAuth(): IProviderAuth {

  const [user, setUser] = React.useState<IAuthData>({} as IAuthData)

  const isAdmin = () => {
    const aUser = user.User
    const isAdmin = (aUser && !aUser.isObjectEmpty() && aUser.role && aUser.role === PitchuraRole.PitchuraAdmin)
    return isAdmin ? true : false
  }

  const localStorage = usePitchuraLocalStorage()

  const [EmailVerifiedStatus, setEmailVerifiedStatus] = React.useState(user.User?.emailVerified)

  const alertError = useAlert().APIError

  const location = useLocation()

  React.useEffect(() => {

    const excludePaths = ['/', '/signup', '/login', '/watch']

    if (!excludePaths.includes(location.pathname)) {
      Refresh()
    }

  }, [])

  const Refresh = () => {

    const storaged = localStorage.user()
    if (!storaged.isObjectEmpty()) {

      setUser(storaged)
      globalThis.JWTToken = storaged.JWTtoken

      // ReloadUser ()
    }
  }


  React.useEffect(() => {

    if (globalThis.JWTToken)
      ReloadUser()

  }, [globalThis.JWTToken])



  const ReloadUser = async () => {

    if (globalThis.JWTToken !== null) {

      API.get('/auth/user')

        .then((res) => {

          const refreshedUser = res.data as UserDAO
          const updatedUser = new UserModel(refreshedUser.Id)

          updatedUser.load(refreshedUser)

          setUser((value) => ({ ...value, User: updatedUser }))

          setEmailVerifiedStatus(updatedUser.emailVerified)

        })
        .catch((error) => {

          if (error && error.response && error.response.status !== 401) {
            LogError(error)
            alertError()
          }

        })
    }
  }

  React.useEffect(() => {

    if (user.Id && user.JWTtoken) {
      localStorage.updateUser(user)
      globalThis.JWTToken = user.JWTtoken
      setEmailVerifiedStatus(user.User?.emailVerified)
    }

  }, [user])


  /* 
    useInterval(() => {
      Refresh()
    }, 2000)
   */

  const SendEmailVerification = async (onSuccess: () => void, onError: (msg: string) => void) => {


    API.post('/auth/email/sendVerificationEmail', { uid: user.Id, email: user.User?.email, host: window.location.origin })

      .then((res) => {

        if (res.status === 200) {
          onSuccess()
        }
        else {
          onError(res.statusText)
        }
      })
      .catch((err) => {
        LogError(err)
        onError(err.response.data)
      })


  }


  const VerifyEmail = (_email: string, _challange: string, onSuccess: () => void, onError: (msg: string) => void) => {

    API.post('/auth/email/verifyemail', { email: _email, challange: _challange })

      .then((res) => {

        if (res.status === 200) InitSession(res, onSuccess, onError)

      })

      .catch((err) => {
        LogError(err)
        onError(err.response.data)

      })
  }


  const setJWTToken = (res: AxiosResponse, onError: (err: string) => void) => {


    const Token = res.headers['authorization']?.replace('BEARER ', '')

    if (!Token) {
      onError("Token not found")
      return null
    }

    globalThis.JWTToken = Token

    setUser({
      ...user,
      JWTtoken: Token
    })

    return Token

  }

  const InitSession = (res: AxiosResponse, onSuccess: (redirectTo: any) => void, onError: (msg: string) => void) => {

    const _user = res.data as UserDAO
    const { redirectTo } = res.data


    const token = setJWTToken(res, onError)

    if (token) {

      const User = new UserModel(_user.Id)
      User.load(_user)

      setUser({
        ...user,
        Id: User.id,
        User: User,
        JWTtoken: token,
        Session: new SessionModel()
      })

      return onSuccess(redirectTo)

    }

  }


  const UpdateUser = async (onSuccess: () => void, onError: (msg: string) => void, userId: number, email?: string, phone?: string, firstName?: string, lastName?: string, title?: string, linkedInURL?: string, companyCity?: string, companyLogo?: string, companyName?: string, companyCountry?: string, companyState?: string) => {

    const personDao =
      {
        Email: email,
        LastName: lastName,
        FirstName: firstName,
        Title: title,
        Mobile: phone,
        LinkedInURL: linkedInURL

      } as UserDAO


    const ventureDAO =
      {
        Name: companyName,
        Logo: companyLogo,
        Country: companyCountry,
        City: companyCity,
        State: companyState

      } as VentureDAO

    personDao.Venture = ventureDAO

    API.put(`/auth/person/${userId}`, personDao)

      .then((res) => {

        if (res.status === 200 && user.Id === userId) {

          setJWTToken(res, () => { LogError('unable to set Token in UpdateUser') })
          ReloadUser()

        }

        onSuccess()


      })

      .catch((err: AxiosError) => {
        LogError(err)
        onError(err.message)

      })


  }

  const EmailLogin = async (email: string, password: string, onSuccess: () => void, onError: (msg: unknown) => void) => {

    // login user with email password 

    API.post('/auth/email/login', { email: email, password: password })

      .then((res) => {

        if (res.status === 200) InitSession(res, onSuccess, onError)

      })
      .catch((err: AxiosError) => {

        if (err.response?.status === 403) {

          onError(err.response?.data)

        } else {
          LogError(err)
          onError("Unexpected error occurred")

        }
        return
      })
  }

  const Logout = async (onSuccess: () => void) => {
    setUser({} as IAuthData)
    localStorage.updateUser({} as IAuthData)
    onSuccess()
  }

  const OauthCallback = async (provider: OAuthProvider, CallbackQS: string, onSuccess: () => void, onError: (msg: string) => void) => {

    let providerURLPart = ''

    switch (provider) {

      case OAuthProvider.Google:
        {
          providerURLPart = 'google'
          break
        }

      case OAuthProvider.LinkedIn:
        {
          providerURLPart = 'linkedin'
          break

        }

      case OAuthProvider.GitHub:
        {
          providerURLPart = 'github'
          break

        }

      case OAuthProvider.Microsoft:
        {
          providerURLPart = 'microsoft'
          break

        }

      case OAuthProvider.Apple:
        {
          providerURLPart = 'apple'
          break

        }


      default:
        {
          throw new Error('unknown OAUTH vendor')
        }
    }

    const url = `/auth/${providerURLPart}/callback${CallbackQS}`

    API.get(url)

      .then((res) => {


        if (res.status === 200) InitSession(res, onSuccess, onError)

      })

      .catch((err: AxiosError) => {
        LogError(err)
        if (err.response?.status === 403 || err.request?.status === 401) {

          onError("A user with the same email already exists")

        }
        else {

          onError("An error occured...")
        }
      })
  }


  const EmailSignup = async (email: string, password: string, OnSuccess: () => void, OnError: (msg: unknown) => void) => {


    API.post('/auth/email/signup', { email: email, password: password })

      .then(

        (res) => {

          if (res.status === 200) {

            InitSession(res, OnSuccess, OnError)

          }
        })

      .catch(

        (err: AxiosError) => {

          if (err.response?.status === 400) {

            OnError(err.response.data)
            return

          }
          LogError(err)
          OnError("Error has occured")

        })

  }


  const EmailRequestPasswordChange = (email: string, onSuccess: () => void, onError: (msg: string) => void) => {

    API.get('/auth/email/requestPasswordChange', { params: { email: email, host: window.location.origin } })

      .then(

        (res) => {

          onSuccess()

        })

      .catch(

        (err) => {
          LogError(err)
          onError(err.message)

        })
  }

  const EmailChangePassword = (email: string, challenge: string, newPassword: string, onSuccess: () => void, onError: (msg: any) => void) => {


    API.post('/auth/email/changePassword', { email: email, challenge: challenge, newPassword: newPassword })

      .then((res) => {

        InitSession(res,  onSuccess , onError)

      })

      .catch((err) => {
        LogError(err)
        onError(err)
      })


  }

  return {

    user,
    isAdmin,
    EmailLogin,
    Logout,
    EmailSignup,
    EmailRequestPasswordChange,
    EmailChangePassword,
    SendEmailVerification,
    VerifyEmail,
    UpdateUser,
    OauthCallback,
    EmailVerifiedStatus

  } as IProviderAuth
}

