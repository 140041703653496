import { PitchStatus } from "Common/Enums"

import { RoutingController } from "Controllers/RoutingController"
import { useAlert } from "hooks/UseAlert"
import { usePitches } from "hooks/UsePitches"
import { PitchModel } from "Model/PitchModel"
import { useHistory, useParams } from "react-router-dom"
import { QaQuestion } from "./Qa_Question"
import { useSpinner } from "UI Elements/XJSpinner"
import { useEffect } from "react"
import { LogError } from "Controllers/Logging"
import { QuestionModel } from "Model/QuestionModel"
import { NotFoundBanner } from "UI Elements/NotFoundBanner"

export function InterviewQuestionPage() {
    const { pid } = useParams<any>()        // Pitch ID 
    const { qid } = useParams<any>()        // question ID

    const pitch = usePitches().getPitch(Number(pid))

    let question
    if (pitch) {
        question = pitch.getQuestion(Number(qid))
    }

    return pitch && question ? 
        <InterviewQuestion pitch={pitch} question={question} />
        :
        <NotFoundBanner
            text={!pitch ? 'This pitch cannot be accessed' : "This question cannot be accessed"}
            urlText='Go home'
            url={RoutingController.Home()}
        />
}

interface InterviewQuestionProps {
    pitch: PitchModel
    question: QuestionModel
}

export function InterviewQuestion(props: InterviewQuestionProps) {

    const history = useHistory()
    const spinner = useSpinner()
    const pitchHook = usePitches()
    const errorAlert = useAlert().error

    const pitch = props.pitch
    const currentQuestion = props.question

    useEffect(() => {
        // sets pitch to Q&A until the inrview is finished 
        if (pitch.status == PitchStatus.new)
            pitch.setStatusAndSave(PitchStatus.draft)
    }, [pitch.id])

    useEffect(() => {
        // saves current interview progress 
        pitch.updateLastAskedQuestion (currentQuestion)
    }, [pitch, currentQuestion.id])

    const nextQuestion = async () => {

        try {

            spinner(true)
            const nextQuestion = await pitch.interviewGoNext(currentQuestion)
            
            if (nextQuestion === null) {
                if (pitch.id) {
                    await pitchHook.reloadPitch(pitch.id)
                }
                history.push(RoutingController.PitchEditor(pitch))

            } else {

                history.push(RoutingController.InterviewStep(nextQuestion))
            
            }
            spinner(false)
        }

        catch (err) {
            LogError(err)
            errorAlert(err)
        }

    }

    const prevQuestion = () => {

        const PrevQuestion = pitch.interviewGoBack(currentQuestion)

        if (PrevQuestion === null) {
            history.push(RoutingController.Home())
            return
        }

        history.push(RoutingController.InterviewStep(PrevQuestion))
        return

    }

    const FinishLater = () => {
        history.push(RoutingController.Home())
    }

    return <QaQuestion

        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        onPrevQuestion={prevQuestion}
        onFinishLater={FinishLater}

    />

}