import API from "api-axios"
import { QuestionType } from "Common/Enums"
import { QuestionContentDAO, QuestionSelection, QuestionTutorial } from "Common/QuestionContentDAO"
import { LogError } from "Controllers/Logging"
import { useAlert } from "hooks/UseAlert"



export class QuestionContentModel {


    async save(): Promise<boolean> {

        try {

            const QContentData =
                {
                    id: this.id,
                    title: this.title,
                    explanation: this.explanation,
                    tutorialVideoLink: this.tutorialsVideoLink,
                    type: Number(this.type),
                    multiAnswers: JSON.stringify(this.multiAnswers),
                    QusetionTutorials: JSON.stringify(this.tutorials),
                    AIPrompt: this.AIPrompt,
                    Topic: this.Topic,
                    Segment: this.Segment

                } as QuestionContentDAO

            if (!this.id) {


                this.id = (await API.post('/admin/question/create', QContentData)).data.id
                return true
            }
            else {


                await API.post(`/admin/question/${this.id}/update`, QContentData)
                return true

            }

        }
        catch (err) {
            LogError(err)
            // FIXME: will crash - move alert to the component layer 
            // useAlert().APIError()

        }

        return false

    }

    id: number | undefined
    title = ''
    type: QuestionType = QuestionType.open
    explanation = ''
    tutorialsVideoLink: string | undefined
    tutorials: QuestionTutorial[] = []
    multiAnswers: QuestionSelection[] = []
    AIPrompt: string | null = null
    Topic = ''
    Segment = ''

    TypeLabel(): string {

        switch (this.type) {

            case QuestionType.open:
                {
                    return "Open"
                }

            case QuestionType.openmultiline:
                {
                    return "Multiline"
                }

            case QuestionType.select:
                {
                    return "Multichoice"
                }

            default:
                {
                    return "unknown"
                }

        }

    }

}