
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PitchModel } from 'Model/PitchModel'
import { Box, Switch, Grid2, Typography, SxProps } from '@mui/material'
import LinkModel from 'Model/LinkModel'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { usePitches } from 'hooks/UsePitches'
import XJBreadcrumbs from '../../UI Elements/XJBreadcrumbs'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { XJSingleInputForm } from 'UI Elements/PopUps/XJSingleInputForm'

import { useAlert } from 'hooks/UseAlert'
import { GridSx, Header, HeaderRightButtons, MainContent, PageTitle, secondaryDefault } from 'XJumpTheme'
import { IconLink } from 'UI Elements/XJIcons'
import { RoutingController } from 'Controllers/RoutingController'
import { useSpinner } from 'UI Elements/XJSpinner'
import XJExplainer from 'UI Elements/PopUps/XJExplainer'
import { ExplainerID } from 'hooks/UseExplainersShouldShow'
import { LogError } from 'Controllers/Logging'
import XJIconButton from 'UI Elements/Buttons/XJIconButton'
import { XJTooltip } from 'UI Elements/XJTooltip'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import XJConfirmationDialog from 'UI Elements/PopUps/XJConfirmationDialog'
import { NotFoundBanner } from 'UI Elements/NotFoundBanner'


function LinkRow(props: any) {

    const link = props.link as (LinkModel)

    const alerts = useAlert()
    const spinner = useSpinner()


    // Set online / offline 

    const [val, setVal] = useState(true)

    const onOffSwitch = (Link: LinkModel) => {

        return <Switch sx={{ position: 'relative', top: 4 }} key={link.id} checked={link.active} onChange={() => {

            handleChange(Link)

            }} />
       

    }

    const handleChange = async (link: LinkModel) => {

        link.SetActive(!link.active)
            .catch(err => {
                alerts.APIError()  
            })

        setVal(!val)   // force a refresh 
    }

    // delete link 

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const onDeleteLink = () => {
        setShowDeleteConfirmation(true)
    }

    const onConfirmDeleteLink = async () => {

        spinner(true)
        setShowDeleteConfirmation(false)

        link.delete().then(() => {
            alerts.success("Link was removed")
            props.onDelete(link.id)
        }).catch((error) => {
            alerts.APIError()
        }).finally(() => {
            spinner(false)
        })
    }


    // Utils 

    const getDayDiffFromNow = (startDate: Date): number => {
        const msInDay = 24 * 60 * 60 * 1000

        // 👇️ explicitly calling getTime()
        return Math.round(
            Math.abs((new Date()).getTime() - startDate.getTime()) / msInDay,
        )
    }

    const ageField = (createdAt: Date | undefined) => {

        if (createdAt) {
            const numberofDays = getDayDiffFromNow(createdAt)
            return `${numberofDays} ${numberofDays === 1 ? 'day' : 'days'}`
        } else {
            return '0 days'
        }

    }


    // MARK: - Render 

    const styles = {
        urlContent: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 4
        } as SxProps
    }

    return <>

        <XJConfirmationDialog
            open={showDeleteConfirmation}
            title={`Delete link "${link.title}"?`}
            ConfirmButtonText='Yes'
            CancelButtonText='no'
            onConfirm={onConfirmDeleteLink}
            onCancel={() => { setShowDeleteConfirmation(false) }}
        />

        <Grid2 size={{ xs: 3 }} sx={GridSx.itemBold}>
            {link.title}
        </Grid2>

        <Grid2 size={{ xs: 2 }} sx={GridSx.item}>
            <Box sx={{ textAlign: 'right' }}>
                {ageField(link.createAt)}
            </Box>
        </Grid2>

        {/* <Grid2 item xs={1} sx={GridSx.item}>
            {link.GetlastView()}
        </Grid2>
        <Grid2 item xs={1} sx={GridSx.item}>
            <Box sx={{ textAlign: 'right' }}>
                {link.viewsCount}
            </Box>
        </Grid2>
        <Grid2 item xs={1} sx={GridSx.item}>
            <Box sx={{ textAlign: 'right' }}>
                {link.GetAvgPercentPlayed()}%
            </Box>
        </Grid2> */}

        <Grid2 size={{ xs: 5 }} sx={GridSx.itemBold}>
            <Box sx={styles.urlContent}>

                <a href={link.UniqueLink} target='_blank' rel="noreferrer" style={{ color: secondaryDefault }}>
                    {link.UniqueLink}
                </a>

                <CopyToClipboard text={link.UniqueLink} onCopy={() => {
                    alerts.success('Link copied to the clipboard')
                }}>
                    <Box sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <IconLink />
                    </Box>
                </CopyToClipboard>

            </Box>
        </Grid2>

        <Grid2 size={{ xs: 1 }} sx={{ ...GridSx.item, padding: 0, justifyContent: 'center' }}>
            {onOffSwitch(link)}
        </Grid2>

        <Grid2 size={{ xs: 1 }} sx={{ ...GridSx.item, padding: 0, justifyContent: 'center' }}>

            <XJIconButton variant='tertiary' onClick={onDeleteLink}>
                <XJTooltip title='Delete' placement="bottom">
                    <DeleteOutlineIcon />
                </XJTooltip>
            </XJIconButton>

        </Grid2>
    </>
}

export function SharedLinksPage() {

    const { pid } = useParams<any>()
    const pitchId = Number(pid)

    const pitch = usePitches().getPitch(pitchId) as PitchModel

    return pitch ? 
        <SharedLinks 
            pitch={pitch}
        />
        : 
        <NotFoundBanner
            text={'This pitch cannot be accessed'}
            urlText='Go home'
            url={RoutingController.Home()}
        />
}

interface SharedLinksProps {
    pitch: PitchModel
}

function SharedLinks(props: SharedLinksProps) {

    const pitch = props.pitch

    const [NewLinkDialogOpen, setNewLinkDialogOpen] = useState(false)

    const spinner = useSpinner()
    const alerts = useAlert()

    const [links, setLinks] = useState<LinkModel[]>(pitch.links)

    const onLinkDelete = () => {
        setLinks(pitch.links)
    }


    // Styling 

    const AddNewLink = () => {

        setNewLinkDialogOpen(true)

    }

    const onAddNewLinkOK = async (value: string) => {

        spinner(true)

        pitch.getNewShareLink(value)
            .then(res => {
                alerts.success('Link created successfully')
                setLinks(pitch.links)
            })
            .catch(err => {

                alerts.APIError()
                LogError(err)

            })
            .finally(() => {
                setNewLinkDialogOpen(false)
                spinner(false)
            })

    }

    const onAddNewlinkCancel = () => {
        setNewLinkDialogOpen(false)
    }

    const explainerCondition = useCallback(() => {
        return pitch && pitch.links && pitch.links.length > 0 ? true : false
    }, [])

    return (
        <>
            <XJExplainer
                id={ExplainerID.SharingLinks}
                title={'Pitch links'}
                welcomeText={"For each pitch you can create many unique links to share with investors. By having different unique links to your pitch, you can track which investor watched your pitch. </br></br> You can also turn off links giving you control on who still has access to your pitch."}
                conditionFn={explainerCondition}
            />

            <XJSingleInputForm
                open={NewLinkDialogOpen}
                title={'New link for sharing'}
                explanation={'Type a title for the link. This is non-public and only used your information.'}
                inputFieldTitle={'Link title'}
                inputFieldInitValue={''}
                onOK={onAddNewLinkOK}
                onCancel={onAddNewlinkCancel}
            />

            <Grid2 container>
                <Grid2 size={{ xs: 12 }} sx={Header}>

                    <XJBreadcrumbs />
                    <Box sx={HeaderRightButtons}>

                        <XJButton variant={'tertiary'} to={RoutingController.Home()}>Back</XJButton>
                        <XJButton variant={'secondary'} onClick={AddNewLink}>
                            New Link
                        </XJButton>
                    </Box>

                </Grid2>

                <Grid2 size={{ xs: 12 }} sx={PageTitle}>

                    <Typography variant='h1'>
                        Pitch links
                    </Typography>

                </Grid2>

                <Grid2 size={{ xs: 12 }} sx={MainContent}>

                    <Box display="flex" flexWrap="nowrap" justifyContent="space-between" width={300}>

                    </Box>

                    <Grid2 container>
                        <Grid2 size={{ xs: 3 }} sx={GridSx.header}>
                            <Typography variant='h3'>Link title</Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 2 }} sx={GridSx.header}>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant='h3'>age</Typography>
                            </Box>
                        </Grid2>

                        {/* <Grid2 item xs={1} sx={GridSx.header}>
                            <Typography variant='h3'>last played</Typography>
                        </Grid2>
                        <Grid2 item xs={1} sx={GridSx.header}>
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant='h3'>Views</Typography>
                            </Box>
                        </Grid2>
                        <Grid2 item xs={1} sx={GridSx.header}>
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant='h3'>% played</Typography>
                            </Box>
                        </Grid2> */}

                        <Grid2 size={{ xs: 5 }} sx={GridSx.header}>
                            <Typography variant='h3'>URL</Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 1 }} sx={{ ...GridSx.header, textAlign: 'center' }}>
                            <Typography variant='h3'>Active</Typography>
                        </Grid2>

                        <Grid2 size={{ xs: 1 }} sx={{ ...GridSx.header, textAlign: 'center' }}>
                            <Typography variant='h3'>Actions</Typography>
                        </Grid2>

                        {links && links.map((link: LinkModel) => (
                            <LinkRow key={link.id} link={link} onDelete={onLinkDelete} />
                        ))}
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    )

}


